@import "../../assets/styles/global.scss";
@import "../../assets/styles/variables";

.contact {
  margin: 0 auto;
  text-align: center;
  max-width: 500px;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      font-size: 1rem;
      margin-bottom: 5px;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      width: calc(100% - 20px);
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid;
      border-color: $light-black;
      border-radius: 5px;
      transition: border-color 0.3s;
      box-sizing: border-box;

      &:focus {
        outline: none;
        border-color: $orange;
      }

      &:invalid {
        border-color: $red;
      }
    }

    textarea {
      resize: vertical;
      min-height: 150px;
    }

    input[type="submit"] {
      width: auto;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background-color: $orange;
      color: $white;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($orange, 10%);
      }
    }

    .error-message {
      font-size: 0.8rem;
      color: $red;
      margin-top: -5px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 20px;
    }

    .success-message {
      font-size: 0.8rem;
      color: $green;
      margin-top: -5px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 20px;
    }
  }

  label.invalid {
    color: $red;
  }
}
