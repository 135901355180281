@import "../../assets/styles/global.scss";
@import "../../assets/styles/variables";

.bubbles-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0);
  // padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  backdrop-filter: blur(10px);
  animation: fadeInBackground 0.5s ease forwards;
}

@keyframes fadeInBackground {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.bubble {
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  background-color: $orange;
  opacity: 0;
  animation: fadeInBubble 0.5s ease forwards;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  position: relative;
}

.bubble::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 50%;
  transition: border-color 0.3s ease;
}

.bubble:hover::before {
  border-color: $black;
}

.bubble1 {
  animation-delay: 0.5s;
}

.bubble2 {
  animation-delay: 1s;
}

.bubble3 {
  animation-delay: 1.5s;
}

.bubble4 {
  animation-delay: 2s;
}

@keyframes fadeInBubble {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 70%;
  max-width: 70%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.modal h2 {
  margin-top: 0;
}

.modal p {
  margin-bottom: 20px;
}

@media only screen and (min-width: 769px) {
  .bubbles-container {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    bottom: unset;
    top: 20px;
    left: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  @keyframes fadeInBubble {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .bubble {
    width: 40px;
    height: 40px;
    margin: 10px 10px 10px 25px;
  }

  .modal {
    width: 40%;
    max-width: 40%;
  }
}
