@import "../../assets/styles/global.scss";
@import "../../assets/styles/variables";

.icon-container {
  color: $light-black;
  text-align: center;
  padding: 0 10rem;

  & i {
    margin: 0 5px 0 5px;
  }
}

@media only screen and (max-width: 768px) {
  .icon-container {
    padding: 0 1rem;
  }
}
